<atom-example-dialog>
  <span dialog-title>Awread có bản cập nhật mới</span>
  <section class="relative h-full  rounded w-full md:w-120 px-10">
    <div class="text-2xl text-center"></div>
    <p class="  py-6 whitespace-pre-line">{{data.description}}</p>
  </section>
  <div dialog-actions class="flex  flex-row justify-between py-4 px-10">
    <div *ngFor="let btn of btns">
      <atom-btn-rectangle (btnClicked)="action(btn)" [text]="btn.text"></atom-btn-rectangle>
    </div>
  </div>
</atom-example-dialog>