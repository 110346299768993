import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Inject, inject } from '@angular/core';

import { Router } from '@angular/router';
import { DialogRef } from '@ngneat/dialog';
import { BtnRectangleAtom } from '../../atoms/btn-rectangle/btn-rectangle.atom.js';
import { ExampleDialogAtom } from '../../atoms/example-dialog/example-dialog.atom.js';

interface data {
  description: string;
  link: string;
}

@Component({
  standalone: true,
  selector: 'dialog-noti-awread-update',
  imports: [CommonModule, BtnRectangleAtom, ExampleDialogAtom],
  templateUrl: './noti-awread-update.dialog.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotiAwreadUpdateDialog {
  isHidden = false;
  btns = [
    {
      text: 'Xem chi tiết',
      type: 'read-detail',
    },
    {
      text: 'Đóng',
      type: 'close',
    },
  ];
  dialogRef = inject(DialogRef);
  router = inject(Router);
  data = this.dialogRef.data;

  action(event: any) {
    console.log(event);
    switch (event.type) {
      case 'close':
        this.dialogRef.close();
        break;
      case 'read-detail':
        console.log('data link', this.data.link)
        this.router.navigateByUrl(this.data.link);
        this.dialogRef.close();
        break;
    }
  }
}
